import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class LayersRepository extends BaseRepository {
    baseUrl = 'management/bituminous-mixtures/layers';

    /**
     * Fetches the next order number
     *
     * @return {*}
     */
    fetchNextOrderNumber() {
        return this.httpClient.get(`${this.baseUrl}/next-order-number`);
    }

    /**
     * Reorders fields
     *
     * @param data
     *
     * @returns {*}
     */
    updateOrder(data) {
        return this.httpClient.put(`${this.baseUrl}/reorder`, data);
    }
}
